<template>
  <div class="addressBook">
    <!-- <van-row class="head">
      <van-col span="20">
        <div class="title">sổ địa chỉ</div>
      </van-col>
      <van-col>
        <img class="addFrinends" @click="go('/addFriends')" src="./assets/icon1.png" />
      </van-col>
    </van-row> -->
    <van-row class="search">
      <van-col span="20">
        <van-search
          v-model="searchValue"
          placeholder="Tìm kiếm"
          @search="onSearch"
        />
      </van-col>
      <van-col>
        <div class="cancle" @click="onSearch">Hủy bỏ</div>
      </van-col>
    </van-row>
    <div class="userItem">
      <UserItem
        :img="require('@/assets/icon/newF.png')"
        text="Bạn bè mới"
        :badgeNum="addFriendsNum"
        @click="go('/newFriends')"
        isLocalImg
      />
    </div>
    <div class="userItem">
      <UserItem
        :img="require('@/assets/icon/group.png')"
        text="Nhóm Trò Chuyện"
        @click="go('/groups')"
        isLocalImg
      />
    </div>
    <div class="placeholder">Danh sách bạn bè</div>
    <List url="/rooms/search/friend" :pageSize="50">
      <template v-slot="{ item }">
        <UserItem
          :img="item.userimg"
          class="userItem"
          :text="item.username"
          @click="toChat(item)"
        />
      </template>
    </List>
  </div>
</template>

<script>
import UserItem from "./components/userItem.vue";
import List from "@/components/List.vue";
import { $post } from "@/utils/request";
import { Toast } from "vant";
export default {
  components: { UserItem, List },
  props: {
    addFriendsNum: 0,
  },
  data() {
    return {
      searchValue: "",
    };
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    onSearch() {
      if(!this.searchValue) return;
      this.$router.push(`/friendList?key=${this.searchValue}`)
    },
    async toChat(item) {
      Toast.loading({duration: 0});
      try {
        const {
          data: { ret, msg, data },
        } = await $post("/rooms/add", { user_ids: item.friend_userid });
        Toast.clear();
        if (ret == 1) {
          this.$router.push(`/ChatRoom?id=${data.id}`);
          return;
        }
        Toast(msg);
      } catch (error) {
        ;
        Toast.clear(); 
      }
    },
  },
};
</script>

<style lang="less" scoped>
.head {
  display: flex;
  align-items: center;
}

.title {
  text-align: center;
  font-weight: 700;
  font-size: 18px * 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
}

.addFrinends {
  width: 20px * 2;
  height: 20px * 2;
}

.search {
  display: flex;
  align-items: center;
}

.cancle {
  font-size: 14px * 2;
}

.placeholder {
  margin-top: 4px * 2;
  background-color: #ededed;
  color: #7e7e7e;
  padding: 4px * 2 20px * 2;
  font-size: 13px * 2;
}

.userItem {
  margin-top: 4px * 2;
  padding: 4px * 2 20px * 2;
}
</style>